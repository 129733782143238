import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Scroller from 'components/Scroller.jsx';
import { colors as viewColors } from 'components/NavBar/NavBar.jsx';
import storeSettings from 'stores/settings.js';

import './Settings.css';

class ViewSettings extends Component {
	
	updateSetting = (e) => {
		storeSettings.update(e.target.name, e.target.options[e.target.selectedIndex].value, true);
	}
	
	render() {
		const { t } = this.props;
		const { data } = storeSettings;
		
		return (
			<>
				<Helmet>
					<meta name="theme-color" content={viewColors.settings[0]} />
				</Helmet>
				<Scroller>
					<main id="settings" className="view">
						<svg viewBox="0 0 1280 320" id="settings-logo">
							<path fill="#6b6" d="M311.6 159.73s12.41-3.94 12.41-31.68-21.16-33.43-25.96-35.11c-4.8-1.68-62.23-3.3-67.61 2.74 0 0-1.56 127.49.57 130.51 2.13 3.02 46.41 3.2 61.17 2.31 14.76-.89 37.87-9.42 37.87-35.92s-18.45-32.85-18.45-32.85zm-43.25-38.95c1.98-1.98 23.12-1.48 23.12 12.04 0 13.51-21.74 14.23-23.12 12.86-1.37-1.38-1.98-22.92 0-24.9zm.96 76.88c-1.37-1.37-1.98-22.91 0-24.89 1.98-1.98 23.12-1.48 23.12 12.04 0 13.5-21.75 14.22-23.12 12.85zM337.12 93.45c3.26-3.26 35.64-2.99 38.63 0s-7.89 97.63 13.75 97.63 10.97-95.21 13.46-97.7c2.49-2.49 35.38-1.78 37.7.53s25.21 136.13-51.76 136.13-51.78-136.59-51.78-136.59z" />
							<path fill="#6b6" d="M456.36 185.98c2.78-2.09 12.2 4.55 23.76 5.94s23.29 1.92 25.43-4.3c2.13-6.22-8.53-9.07-13.34-10.14-4.8-1.07-23.47-8.36-28.63-13.69s-14.58-20.8-12.09-39.12c2.49-18.31 8.53-27.56 22.4-31.47s29.26-4.59 40.92-3.71c11.66.88 26.69 3.28 28.81 7.32s-.38 33.66-4.82 35.51c-4.44 1.85-16.36-6.22-26.14-8-9.78-1.78-20.63-1.24-22.58 3.73s2.13 8.18 10.85 11.02c8.71 2.84 29.87 8.18 38.23 18.49s13.69 25.96 7.82 42.85c-5.87 16.89-18.67 24.07-31.83 26.85s-29.91 3.88-37.37 3.52c-7.47-.36-24.75-1.58-26.67-4.33-1.93-2.73 2.47-38.38 5.25-40.47zM707.4 227.81c2.63-3.2 2.35-133.16 0-134.86-2.35-1.7-40.55-3.34-43.01-1.06-2.45 2.28-1.89 134.5 0 135.92 1.9 1.42 40.38 3.2 43.01 0zM561.51 186.51c2.78-2.09 12.2 4.55 23.76 5.94s23.29 1.92 25.43-4.3c2.13-6.22-8.53-9.07-13.34-10.14-4.8-1.07-23.47-8.36-28.63-13.69s-14.58-20.8-12.09-39.12 8.53-27.56 22.4-31.47 29.26-4.59 40.92-3.71c11.66.88 26.69 3.28 28.81 7.32 2.11 4.04-.38 33.66-4.82 35.51-4.44 1.85-16.36-6.22-26.14-8-9.78-1.78-20.63-1.24-22.58 3.73-1.96 4.98 2.13 8.18 10.85 11.02 8.71 2.84 29.87 8.18 38.23 18.49 8.36 10.31 13.69 25.96 7.82 42.85-5.87 16.89-18.67 24.07-31.83 26.85-13.16 2.78-29.91 3.88-37.37 3.52-7.47-.36-24.75-1.58-26.67-4.33-1.93-2.73 2.48-38.38 5.25-40.47z" />
							<path fill="#0090ea" d="M802.46 94.36c-2.77-3.03-59.53-2.93-62.03-.91-2.49 2.02-26.13 132.04-24.51 133.91 1.62 1.87 39.67 2.1 41.51.45s1.54-23.08 4.09-24.63c2.55-1.55 17.18-2.31 19.94 0 2.76 2.31 1.56 22.98 4.36 24.45 2.8 1.47 40.19 3.03 41.77.18S805.24 97.39 802.46 94.36zm-23.31 81.12c-2.33 2.33-14.74 1.93-16.67 0-1.93-1.93 3.26-51.66 8.1-51.66 4.84.01 10.91 49.33 8.57 51.66zM836.19 93.45c2.84-2.56 82.47-1.35 83.82 0 1.35 1.35 1.7 37.18.55 38.43-1.16 1.24-41.22-1.41-42.53.01-1.3 1.42-1.15 10.46-.12 11.76 1.04 1.3 26.87-.65 27.87.77.99 1.42 1.06 32.43-.04 33.68-1.1 1.26-26.79-1.32-27.95.63s-1.13 9.75.24 11.01c1.36 1.26 41.37-1.88 42.53 0s1.75 37.19.7 38.52-83.27 1.68-85.06-.36c-1.79-2.05-2.84-131.89-.01-134.45zM1025.95 98.8c3.07 4.4-.53 29.42-4.51 32.27-3.98 2.85-30.65-11.55-43.19 1.16s-13.25 60.63 7.82 60.63c9.87 0 12.76-12.68 13.47-17.47s-.01-12.16 2.08-13.91 24.43-.32 26.06 1.37 2.68 10.75 2.68 23.46c0 20.33-3.08 35.22-4.86 37.69-3.26 4.52-60.6 12.96-77.67-5.44s-22.76-48.01-18.67-72.99c4.09-24.98 18.58-45.83 45.34-51.39 26.78-5.56 48.38.22 51.45 4.62z" />
							<path fill="#4cae50" d="M657.66 91.14c.37 2.83-.31 6.98 4.32 6.37 4.63-.6 3.38-4.24 3.01-7.07M687.19 87.28c.37 2.83 2.09 6.66-2.54 7.27-4.63.6-4.36-3.23-4.73-6.06" />
							<path fill="#6b6" d="M687.9 49.64c-.51-2.2-1.04-5.95-2.33-7.08-1.3-1.13-15.4.5-19.11.99-3.7.48-17.76 2.53-18.72 3.95-.96 1.43-.5 5.18-.43 7.44.07 2.26 1.87 16.69 2.27 18.96.4 2.27 1.56 9.91 2.09 11.93.53 2.02 1.13 5.36 1.84 5.86.71.49 3.92.43 5.65.27 1.73-.16 7.99-.6 13.45-1.31 5.47-.71 11.62-1.9 13.34-2.19 1.71-.29 4.83-1.06 5.39-1.72s.28-4.05.28-6.13c-.01-2.09-.84-9.77-1.04-12.06s-2.17-16.71-2.68-18.91zm-26.91 37.73c-2.09.27-4-1.2-4.27-3.29-.27-2.09 1.2-4 3.29-4.27 2.09-.27 4 1.2 4.27 3.29.27 2.09-1.21 4-3.29 4.27zM683 84.5c-2.09.27-4-1.2-4.27-3.29-.27-2.09 1.2-4 3.29-4.27 2.09-.27 4 1.2 4.27 3.29.27 2.08-1.2 3.99-3.29 4.27zm3.83-13.16c-.63 1.24-6 3.04-16.17 4.37-10.17 1.33-15.82.97-16.75-.07-.93-1.04-1.47-8.23-1.29-12.39s2.38-12.71 14.54-14.3c12.16-1.59 16.47 6.1 17.72 10.08s2.58 11.07 1.95 12.31z" />
							<path fill="#fe3c00" d="M1039.25 193.1c2.96-2.96 33.87-1.66 36.09.57s2.41 32.14.4 34.14c-2.01 2.01-34.39 1.39-36.49-.71-2.11-2.11-2.96-31.04 0-34zM1086.51 93.66c2.84-2.56 82.47-1.35 83.82 0 1.35 1.35 1.7 37.18.55 38.43-1.16 1.24-41.22-1.41-42.53.01s-1.15 10.46-.12 11.76 26.87-.65 27.87.77c.99 1.42 1.06 32.43-.04 33.68-1.1 1.26-26.79-1.32-27.95.63s-1.13 9.75.24 11.01 41.37-1.88 42.53 0 1.75 37.19.7 38.52-83.27 1.68-85.06-.36c-1.79-2.05-2.84-131.89-.01-134.45zM1183.97 93.45c2.84-2.56 82.47-1.35 83.82 0 1.35 1.35 1.7 37.18.55 38.43-1.16 1.24-41.22-1.41-42.53.01-1.3 1.42-1.15 10.46-.12 11.76s26.87-.65 27.87.77c.99 1.42 1.06 32.43-.04 33.68-1.1 1.26-26.79-1.32-27.95.63s-1.13 9.75.24 11.01c1.36 1.26 41.37-1.88 42.53 0s1.75 37.19.7 38.52-83.27 1.68-85.06-.36c-1.8-2.05-2.85-131.89-.01-134.45z" />
							<path fill="#d84214" d="M1103.38 88.09l-15.61 3.12s-5.48 6.95-5.28 7.99c.21 1.04 1.88 2.17 2.51 1.96s1.67-3.21 2.31-3.57c.64-.37 9.4-2.18 9.4-2.18h.01s8.79-1.69 9.52-1.6 2.85 2.46 3.51 2.41c.66-.05 1.77-1.74 1.56-2.78-.2-1.04-7.93-5.35-7.93-5.35zm-1.48 2.93c-.11.51-5.75 1.46-5.75 1.46h-.01s-5.57 1.29-5.87.86c-.3-.43 2.07-2.96 2.07-2.96l6.51-1.3s3.15 1.43 3.05 1.94zM1086.34 43.43s-7.58 1.55-7.88 2.34 4.32 3.84 4.32 3.84-.13 2.29-2.27 1.07c-2.14-1.23-6.78-5.14-6.68-5.85.1-.7 6.59-2.53 12.06-3.63 5.47-1.09 12.17-1.9 12.53-1.29.36.61-2.41 6.01-3.92 7.96-1.51 1.95-2.5-.12-2.5-.12s3.09-4.59 2.51-5.2-8.17.88-8.17.88z" />
							<path fill="#fe3c00" d="M1113.25 71.8c-3.98-14.39-7.14-20.34-10.6-22.89-3.46-2.55-12.02-1.11-15.3-.49h-.01c-3.27.69-11.72 2.65-13.94 6.33-2.22 3.68-2.85 10.39-.99 25.2.88 6.3 1.47 9.37 3.89 11.35s7.81.78 19.31-1.51h.01c11.5-2.3 16.94-3.27 18.41-6.02 1.46-2.76.83-5.81-.78-11.97zm-25.58 16.51c-2 .4-3.94-.9-4.34-2.89-.4-2 .9-3.94 2.89-4.34s3.94.9 4.34 2.89c.4 2-.89 3.94-2.89 4.34zm14.69-2.93c-2 .4-3.94-.9-4.34-2.89s.9-3.94 2.89-4.34c2-.4 3.94.9 4.34 2.89.41 2-.89 3.94-2.89 4.34zm6.14-12.99c-.53 1.24-5.59 3.32-15.32 5.27-9.73 1.94-15.2 1.97-16.16 1.03-.97-.94-1.96-7.84-2.05-11.88-.09-4.03 1.47-12.42 13.1-14.74 11.63-2.32 16.3 4.82 17.76 8.57 1.46 3.76 3.2 10.51 2.67 11.75z" />
							<path fill="#0276bc" d="M897.79 86.44c-.59 2.86-2.64 6.64 2.03 7.62 4.68.97 4.7-2.96 5.29-5.82M927.63 92.66c-.59 2.86-.23 7.15-4.9 6.17-4.68-.97-3.12-4.59-2.53-7.45" />
							<path fill="#0276bc" d="M917.86 42.55s-3.39-10.3-3.88-10.74c-.49-.45-3.85-1.4-4.21-.65s2.22 6.74 4.07 10.89M927.51 44.56s7.22-8.09 7.85-8.3c.63-.21 4.09.25 4.12 1.08.03.83-4.73 5.29-8.08 8.35" />
							<path fill="#0090ea" d="M941 56.51c.25-2.3 1-6.1.13-7.63-.87-1.53-15.06-4.7-18.8-5.48-3.74-.78-18.02-3.54-19.43-2.48-1.41 1.05-2.23 4.84-2.93 7.05-.69 2.21-3.81 16.76-4.19 19.09-.38 2.33-1.83 10.1-2 12.23-.17 2.13-.71 5.57-.19 6.28s3.65 1.74 5.37 2.17c1.73.43 7.92 2.11 13.45 3.26 5.52 1.15 11.88 2.08 13.63 2.37 1.75.3 5.03.61 5.79.16.76-.45 1.64-3.82 2.33-5.84.7-2.02 2.47-9.73 3.05-12.01.59-2.28 3.54-16.87 3.79-19.17zm-38.72 27.41c-2.11-.44-3.47-2.51-3.03-4.62s2.51-3.47 4.62-3.03c2.11.44 3.47 2.51 3.03 4.62-.45 2.12-2.52 3.47-4.62 3.03zm22.24 4.64c-2.11-.44-3.47-2.51-3.03-4.62s2.51-3.47 4.62-3.03c2.11.44 3.47 2.51 3.03 4.62-.44 2.11-2.5 3.47-4.62 3.03zm8.14-11.44c-1.03.99-6.82.92-17.1-1.22-10.28-2.14-15.62-4.39-16.17-5.7-.55-1.32 1.35-8.45 2.93-12.42s6.58-11.48 18.87-8.92c12.29 2.56 13.87 11.45 13.74 15.72-.14 4.26-1.24 11.56-2.27 12.54z" />
							<path fill="#fe3c00" d="M139.51 265.2h-73.4s-31.08 26.49-31.08 31.38 6.55 11.54 9.57 11.14c3.02-.4 10.45-13 13.68-14.07s44.5-1.36 44.5-1.36h.04s41.27.29 44.5 1.36 10.66 13.68 13.68 14.07 9.57-6.26 9.57-11.14-31.06-31.38-31.06-31.38zm-9.35 11.92c-.95 2.2-27.33 1.39-27.33 1.39h-.04s-26.38.81-27.33-1.39c-.95-2.2 12.02-11.51 12.02-11.51h30.64c.02 0 12.99 9.31 12.04 11.51z" />
							<path fill="#0090ea" d="M81.34 56.03s-23.6-40.91-26.1-42.37-17.67-2.57-18.55.95 15.47 26.76 27.05 42.81M124.3 56.03s23.6-40.91 26.1-42.37c2.49-1.47 17.67-2.57 18.55.95s-15.47 26.76-27.05 42.81" />
							<path fill="#6b6" d="M194.13 93.68c-1-10.03-1.14-26.92-6.22-32.67-5.09-5.75-68.42-6.67-85.08-6.67s-80.01.92-85.08 6.67c-5.09 5.75-5.22 22.62-6.22 32.67s-1.36 74.89-.92 85.15 1.17 44.72 2.35 53.95c1.17 9.24 1.91 24.38 4.76 26.98 2.84 2.58 17.1 4.16 24.84 4.46 7.74.29 35.67 1.98 60.26 1.98s52.52-1.69 60.26-1.98c7.74-.29 21.99-1.88 24.85-4.47s3.59-17.74 4.76-26.98c1.17-9.24 1.91-43.69 2.35-53.95.44-10.27.09-75.11-.91-85.14zM53.31 245.03c-9.4 0-17.01-7.61-17.01-17.01 0-9.4 7.61-17.01 17.01-17.01s17.01 7.61 17.01 17.01c-.01 9.38-7.63 17.01-17.01 17.01zm99.02 0c-9.4 0-17.01-7.61-17.01-17.01 0-9.4 7.61-17.01 17.01-17.01s17.01 7.61 17.01 17.01c0 9.38-7.61 17.01-17.01 17.01zm24.53-55.99c-3.52 5.13-28.3 9.97-74.04 9.97s-70.52-4.84-74.04-9.97c-3.52-5.13-1.76-37.24 1.47-55.57s17.89-54.83 72.57-54.83c14.81 0 26.68 2.68 36.18 6.95 25.6 11.49 34.04 34.53 36.39 47.88 3.23 18.33 4.99 50.43 1.47 55.57z" />
						</svg>
						<label htmlFor="settings-lang">
							<svg viewBox="0 0 24 24">
								<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
							</svg>
							{t('settings.language')}
						</label>
						<select id="settings-lang" name="lang" defaultValue={data.lang} onInput={this.updateSetting}>
							<option value="et">Eesti keel</option>
							<option value="en">English</option>
							<option value="ru">Русский</option>
						</select>
						<label htmlFor="settings-theme">
							<svg viewBox="0 0 24 24">
								<path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
							</svg>
							{t('settings.theme')}
						</label>
						<select id="settings-theme" name="theme" defaultValue={data.theme} onInput={this.updateSetting}>
							<option value="light">{t('settings.theme-light')}</option>
							<option value="dark">{t('settings.theme-dark')}</option>
						</select>
						<label htmlFor="settings-view">
							<svg viewBox="0 0 24 24">
								<path d="M7 19h10V4H7v15zm-5-2h4V6H2v11zM18 6v11h4V6h-4z" />
							</svg>
							{t('settings.view')}
						</label>
						<select id="settings-view" name="view" defaultValue={data.view} onInput={this.updateSetting}>
							<option value="search">{t('settings.view-search')}</option>
							<option value="favorites">{t('settings.view-favorites')}</option>
							<option value="map">{t('settings.view-map')}</option>
						</select>
						<label>
							<svg viewBox="0 0 24 24">
								<path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
							</svg>
							{t('settings.credits')}
						</label>
						<section>
							<h1 className="settings-first_party">{t('settings.creditees.programmer')}</h1>
							<a href="https://facebook.com/karlkoorna" target="_blank" rel="noopener noreferrer">Karl Köörna</a>
							<h1 className="settings-first_party">{t('settings.creditees.designer')}</h1>
							<a href="https://facebook.com/kaur.hendrikson" target="_blank" rel="noopener noreferrer">Kaur Hendrikson</a>
							<h1 className="settings-third_party">{t('settings.creditees.gtfs')}</h1>
							<a href="https://mnt.ee" target="_blank" rel="noopener noreferrer">Maanteeamet</a>
							<h1 className="settings-third_party">{t('settings.creditees.siri')}</h1>
							<a href="https://tallinn.ee/est/transpordiamet" target="_blank" rel="noopener noreferrer">Tallinna Transpordiamet</a>
							<h1 className="settings-third_party">{t('settings.creditees.elron')}</h1>
							<a href="https://elron.ee">Elron</a>
						</section>
						<label>
							<svg viewBox="0 0 24 24">
								<path d="M15 9H9v6h6V9zm-2 4h-2v-2h2v2zm8-2V9h-2V7c0-1.1-.9-2-2-2h-2V3h-2v2h-2V3H9v2H7c-1.1 0-2 .9-2 2v2H3v2h2v2H3v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6H7V7h10v10z" />
							</svg>
							{t('settings.version')}
						</label>
						<section>{process.env['REACT_APP_VER_WEB']} <small>(API {process.env['REACT_APP_VER_API']})</small></section>
					</main>
				</Scroller>
			</>
		);
	}
	
}

export default withTranslation()(ViewSettings);
