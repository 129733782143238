import React from 'react';

import { colors as viewColors } from 'components/NavBar/NavBar.jsx';
import storeFavorites from 'stores/favorites.js';

const $app = document.getElementById('app');

// Vehicle and stop colors.
export const colors = {
	bus: [ '#66bb6a', '#4caf50' ],
	trol: [ '#0091ea', '#0277bd' ],
	tram: [ '#ff3d00', '#d84315' ],
	train: [ '#ff6d00', '#e65100' ],
	coach_c: [ '#d32f2f', '#b71c1c' ],
	coach_cc: [ '#673ab7', '#512da8' ]
};

// Add CSS variables for above colors.
for (const vehicle of Object.entries(colors)) {
	$app.style.setProperty(`--color-vehicle-${vehicle[0]}-light`, vehicle[1][0]);
	$app.style.setProperty(`--color-vehicle-${vehicle[0]}-dark`, vehicle[1][1]);
}

export default function Icon({ id, className, shape, type, checkFavorite, forMap }) {
	const [ primaryColor, secondaryColor ] = colors[type];
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={forMap ? '-128 -128 1280 1280' : '0 0 1024 1024'} id={id} className={className} fill={shape === 'stop' ? 'white' : null}>
			{shape === 'stop' ? <path fill={primaryColor} d="M640 1024.5H384c-211.2 0-384-172.8-384-384v-256C0 173.3 172.8.5 384 .5h256c211.2 0 384 172.8 384 384v256c0 211.2-172.8 384-384 384z" /> : null}
			{{
				bus: [
					<>
						<path fill={secondaryColor} d="M285.5 826.2c0 43.3-18.3 104.7 52.7 104.7s59.3-57.3 59.3-100.7m341-4c0 43.3 18.3 104.7-52.7 104.7s-59.3-57.3-59.3-100.7" />
						<path fill={primaryColor} d="M823.4 259.8c-3.4-34.2-3.9-91.8-21.2-111.4-17.3-19.6-233.3-22.8-290.2-22.8s-272.8 3.2-290.2 22.8c-17.3 19.6-17.8 77.2-21.2 111.4s-4.6 255.4-3.1 290.4 4 152.5 8 184 6.5 83.2 16.3 92c9.8 8.8 58.4 14.2 84.8 15.3s121.7 6.8 205.5 6.8 179.1-5.7 205.5-6.8 75-6.4 84.8-15.3c9.8-8.8 12.3-60.5 16.3-92s6.5-149 8-184 .1-256.2-3.3-290.4zM343.1 775.9c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-25.9 58-58 58zm337.8 0c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-26 58-58 58zM764.5 585c-12 17.5-96.5 34-252.5 34s-240.5-16.5-252.5-34-6-127 5-189.5 61-187 247.5-187 236.5 124.5 247.5 187 17 172 5 189.5z" />
					</>,
					<>
						<path d="M313.4 773.2c0 38-16.1 91.7 46.2 91.7s52-50.2 52-88.2m299-3.5c0 38 16.1 91.7-46.2 91.7-62.2 0-52-50.2-52-88.2" />
						<path d="M784.9 276.7c-3-30-3.4-80.5-18.6-97.6s-204.5-20-254.3-20-239.2 2.8-254.3 19.9c-15.2 17.1-15.6 67.7-18.6 97.6s-4 223.9-2.8 254.5c1.2 30.7 3.5 133.7 7 161.3 3.5 27.6 5.7 72.9 14.3 80.7 8.6 7.7 51.1 12.5 74.3 13.3 23.1.9 106.6 5.9 180.2 5.9s157-5.1 180.2-5.9c23.1-.9 65.7-5.6 74.3-13.3 8.6-7.7 10.7-53 14.3-80.7 3.5-27.6 5.7-130.6 7-161.3 1.2-30.6 0-224.5-3-254.4zM364 729c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8c28.1 0 50.8 22.7 50.8 50.8S392.1 729 364 729zm296 0c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8 50.8 22.7 50.8 50.8S688 729 660 729zm73.3-167.2c-10.5 15.3-84.5 29.8-221.3 29.8s-210.7-14.5-221.3-29.8c-10.5-15.3-5.3-111.3 4.3-166.1s53.6-164 217-164 207.3 109.1 216.9 163.9c9.6 54.8 14.9 150.8 4.4 166.2z" />
					</>
				],
				trol: [
					<>
						<path fill={secondaryColor} d="M444.4 184.7s-80.5-139.5-89-144.5-60.3-8.7-63.3 3.3 52.8 91.3 92.3 146m206.5-4.8s80.5-139.5 89-144.5 60.3-8.7 63.3 3.3-52.8 91.3-92.3 146" />
						<path fill={secondaryColor} d="M285.5 883.9c0 43.3-18.3 104.7 52.7 104.7s59.3-57.3 59.3-100.7m341-4c0 43.3 18.3 104.7-52.7 104.7s-59.3-57.3-59.3-100.7" />
						<path fill={primaryColor} d="M823.4 317.5c-3.4-34.2-3.9-91.8-21.2-111.4-17.3-19.6-233.3-22.8-290.2-22.8s-272.8 3.2-290.2 22.8c-17.3 19.6-17.8 77.2-21.2 111.4s-4.6 255.4-3.1 290.4 4 152.5 8 184 6.5 83.2 16.3 92c9.8 8.8 58.4 14.2 84.8 15.3s121.7 6.8 205.5 6.8 179.1-5.7 205.5-6.8 75-6.4 84.8-15.3c9.8-8.8 12.3-60.5 16.3-92s6.5-149 8-184 .1-256.2-3.3-290.4zM343.1 833.7c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-25.9 58-58 58zm337.8 0c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-26 58-58 58zm83.6-190.9c-12 17.5-96.5 34-252.5 34s-240.5-16.5-252.5-34-6-127 5-189.5 61-187 247.5-187 236.5 124.5 247.5 187 17 172 5 189.5z" />
					</>,
					<>
						<path d="M322.7 811.7c0 36.1-15.3 87.2 43.9 87.2s49.5-47.9 49.5-84m284.4-3.2c0 36.1 15.3 87.2-43.9 87.2s-49.5-47.9-49.5-84M455.3 228.5S388.2 112.2 381.1 108c-7.1-4.1-50.3-7.3-52.8 2.7s43.9 76.1 76.9 121.7m172.3-3.9s67.1-116.3 74.2-120.5c7.1-4.1 50.3-7.3 52.8 2.7s-43.9 76.1-76.9 121.7" />
						<path d="M771.3 339.4c-2.8-28.5-3.3-76.6-17.7-92.9-14.5-16.3-194.6-18.9-241.9-18.9s-227.6 2.6-241.9 18.9c-14.5 16.3-14.8 64.4-17.7 92.9-2.8 28.5-3.8 213-2.6 242.2 1.2 29.2 3.4 127.2 6.6 153.5 3.3 26.3 5.4 69.4 13.6 76.7 8.2 7.4 48.6 11.9 70.7 12.7 22.1.9 101.5 5.7 171.3 5.7s149.4-4.8 171.3-5.7c22-.9 62.6-5.3 70.7-12.7 8.2-7.4 10.2-50.5 13.6-76.7s5.4-124.2 6.6-153.5c1.2-29.2.3-213.7-2.6-242.2zM370.8 769.8c-26.8 0-48.4-21.6-48.4-48.4S344 673 370.8 673s48.4 21.6 48.4 48.4-21.7 48.4-48.4 48.4zm281.6 0c-26.8 0-48.4-21.6-48.4-48.4s21.6-48.4 48.4-48.4 48.4 21.6 48.4 48.4-21.6 48.4-48.4 48.4zm69.8-159.2c-10 14.6-80.5 28.4-210.6 28.4S311 625.3 300.9 610.6c-10-14.6-5-106 4.1-158.1 9.4-52.1 51-155.9 206.6-155.9S708.9 400.4 718 452.5c9.2 52.2 14.2 143.5 4.2 158.1z" />
					</>
				],
				tram: [
					<>
						<path fill={secondaryColor} d="M637.2 838.8H386.8s-106 90.3-106 107 22.3 39.3 32.7 38 35.7-44.3 46.7-48 151.8-4.7 151.8-4.7h.1s140.8 1 151.8 4.7 36.3 46.7 46.7 48 32.7-21.3 32.7-38-106.1-107-106.1-107zm-31.9 40.7c-3.3 7.5-93.2 4.8-93.2 4.8h-.1s-89.9 2.8-93.2-4.8 41-39.3 41-39.3h104.5c0 .1 44.2 31.8 41 39.3zM512 80s-121.7.5-128.7 11.7 54.8 72.5 54.8 72.5-9 35-38.3 9.5S311 73.5 314.8 63 424.3 44.3 512 44.3 705.5 52.5 709.3 63s-55.8 85.3-85 110.8-38.3-9.5-38.3-9.5 61.8-61.3 54.8-72.5S512 80 512 80z" />
						<path fill={primaryColor} d="M817.4 501.3C814.9 399.7 794 290.5 762 217.5s-154.3-61.7-250-61.7-218-11.3-250 61.7-52.9 182.2-55.4 283.8C204 603 182.3 799 224.2 818.5s227.3 22.7 287.8 22.7 246-3.2 287.8-22.7C841.7 799 820 603 817.4 501.3zM392.8 792.9c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-25.9 58-58 58zm238.4 0c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-26 58-58 58zm133.3-181.4c-12 17.5-96.5 34-252.5 34s-240.5-16.5-252.5-34-6-127 5-189.5 61-187 247.5-187 236.5 124.5 247.5 187 17 172 5 189.5z" />
					</>,
					<>
						<path d="M616.4 774.9H407.5s-88.4 75.4-88.4 89.2c0 13.9 18.6 32.8 27.2 31.7s29.7-37 39-40c9.2-3.1 126.6-3.9 126.6-3.9h.1s117.4.8 126.6 3.9c9.2 3.1 30.3 39 39 40 8.6 1.1 27.2-17.8 27.2-31.7 0-13.8-88.4-89.2-88.4-89.2zm-26.7 34c-2.7 6.3-77.7 4-77.7 4h-.1s-75 2.3-77.7-4c-2.7-6.3 34.2-32.7 34.2-32.7h87.2c-.1 0 36.8 26.4 34.1 32.7zM512 142.1s-101.6.4-107.3 9.8c-5.8 9.4 45.7 60.5 45.7 60.5s-7.5 29.2-31.9 7.9c-24.4-21.3-74-83.6-70.8-92.3 3.1-8.7 91.3-15.6 164.5-15.6s161.4 6.9 164.5 15.6-46.5 71-70.8 92.3-31.9-7.9-31.9-7.9 51.5-51.1 45.7-60.5c-6.2-9.4-107.7-9.8-107.7-9.8z" />
						<path d="M766.7 493.5c-2.2-84.8-19.5-175.8-46.3-236.7s-128.6-51.5-208.5-51.5-181.8-9.4-208.5 51.5-44.1 152-46.3 236.7c-2.2 84.8-20.3 248.2 14.7 264.6 34.9 16.2 189.6 18.9 240.1 18.9S717 774.3 752 758.1c34.9-16.4 16.8-179.9 14.7-264.6zM412.6 736.7c-26.7 0-48.3-21.7-48.3-48.3 0-26.7 21.7-48.3 48.3-48.3 26.6 0 48.3 21.7 48.3 48.3s-21.6 48.3-48.3 48.3zm198.7 0c-26.7 0-48.3-21.7-48.3-48.3 0-26.7 21.7-48.3 48.3-48.3 26.7 0 48.3 21.7 48.3 48.3.2 26.6-21.5 48.3-48.3 48.3zm111.3-151.4c-10 14.6-80.5 28.4-210.6 28.4s-200.6-13.8-210.6-28.4c-10-14.6-5-105.9 4.2-158 9.2-52.1 50.9-156 206.5-156s197.2 103.8 206.5 156c8.9 52.1 13.9 143.4 4 158z" />
					</>
				],
				train: [
					<>
						<path fill={secondaryColor} d="M637.2 813.8H386.8s-106 90.3-106 107 22.3 39.3 32.7 38c10.3-1.3 35.7-44.3 46.7-48s151.8-4.7 151.8-4.7h.1s140.8 1 151.8 4.7 36.3 46.7 46.7 48 32.7-21.3 32.7-38-106.1-107-106.1-107zm-31.9 40.7c-3.3 7.5-93.2 4.8-93.2 4.8h-.1s-89.9 2.8-93.2-4.8 41-39.3 41-39.3h104.5c0 .1 44.2 31.8 41 39.3z" />
						<path fill={primaryColor} d="M827.4 373c-.4-133-10.8-233.5-24-249.9S677.6 100.3 512 100.3s-278.1 6.4-291.4 22.8c-13.2 16.4-23.6 117-24 249.9s4.4 407.8 24.8 427.4c20.4 19.6 213.4 22.8 290.6 22.8s270.1-3.2 290.6-22.8C823 780.8 827.8 506 827.4 373zM343.5 752.5c-33.4 0-60.5-27.1-60.5-60.5s27.1-60.5 60.5-60.5S404 658.6 404 692s-27.1 60.5-60.5 60.5zm337 0c-33.4 0-60.5-27.1-60.5-60.5s27.1-60.5 60.5-60.5S741 658.6 741 692s-27.1 60.5-60.5 60.5zm55.7-383.3c-10.7 88-19 191.7-43 206-24 14.3-104.3 22.3-181.2 22.3s-157.2-8-181.2-22.3-32.3-118-43-206c-10.7-88-17.7-157.9-1.6-174s107.6-8.3 225.8-8.3 209.7-7.8 225.8 8.3 9 86-1.6 174z" />
					</>,
					<>
						<path d="M617.5 751.6h-211s-89.4 76.2-89.4 90.2c0 14.1 18.8 33.2 27.5 32 8.7-1.2 30.1-37.3 39.3-40.5 9.2-3 127.9-4 127.9-4h.1s118.7.8 127.9 4c9.2 3 30.6 39.3 39.3 40.5 8.7 1.2 27.5-17.9 27.5-32 0-14-89.1-90.2-89.1-90.2zM590.6 786c-2.7 6.3-78.6 4-78.6 4h-.1s-75.9 2.3-78.6-4c-2.7-6.3 34.5-33 34.5-33H556c.1-.1 37.3 26.7 34.6 33z" />
						<path d="M777.8 380c-.3-112.2-9.1-196.8-20.2-210.7-11.1-13.8-106-19.3-245.6-19.3s-234.5 5.4-245.6 19.2c-11.1 13.8-19.9 98.6-20.2 210.7-.3 112.2 3.7 343.8 21 360.3S447 759.6 512 759.6s227.8-2.7 245-19.2 21.3-248.2 20.8-360.4zM369.9 699.9c-28.1 0-51-22.9-51-51s22.9-51 51-51 51 22.9 51 51c0 28.3-22.8 51-51 51zm284.1 0c-28.1 0-51-22.9-51-51s22.9-51 51-51 51 22.9 51 51c0 28.3-22.7 51-51 51zm46.9-323.1c-9 74.2-16.1 161.6-36.3 173.6-20.2 12.1-87.9 18.8-152.8 18.8s-132.5-6.7-152.8-18.8c-20.2-12.1-27.3-99.5-36.3-173.6s-15-133.1-1.4-146.7c13.6-13.6 90.7-7 190.3-7s176.8-6.6 190.3 7c14 13.6 8.1 72.5-1 146.7z" />
					</>
				],
				coach_c: [
					<>
						<path fill={secondaryColor} d="M285.5 826.2c0 43.3-18.3 104.7 52.7 104.7s59.3-57.3 59.3-100.7m341-4c0 43.3 18.3 104.7-52.7 104.7s-59.3-57.3-59.3-100.7" />
						<path fill={primaryColor} d="M823.4 259.8c-3.4-34.2-3.9-91.8-21.2-111.4-17.3-19.6-233.3-22.8-290.2-22.8s-272.8 3.2-290.2 22.8c-17.3 19.6-17.8 77.2-21.2 111.4s-4.6 255.4-3.1 290.4 4 152.5 8 184 6.5 83.2 16.3 92c9.8 8.8 58.4 14.2 84.8 15.3s121.7 6.8 205.5 6.8 179.1-5.7 205.5-6.8 75-6.4 84.8-15.3c9.8-8.8 12.3-60.5 16.3-92s6.5-149 8-184 .1-256.2-3.3-290.4zM343.1 775.9c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-25.9 58-58 58zm337.8 0c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-26 58-58 58zM764.5 585c-12 17.5-96.5 34-252.5 34s-240.5-16.5-252.5-34-6-127 5-189.5 61-187 247.5-187 236.5 124.5 247.5 187 17 172 5 189.5z" />
					</>,
					<>
						<path d="M313.4 773.2c0 38-16.1 91.7 46.2 91.7s52-50.2 52-88.2m299-3.5c0 38 16.1 91.7-46.2 91.7-62.2 0-52-50.2-52-88.2" />
						<path d="M784.9 276.7c-3-30-3.4-80.5-18.6-97.6s-204.5-20-254.3-20-239.2 2.8-254.3 19.9c-15.2 17.1-15.6 67.7-18.6 97.6s-4 223.9-2.8 254.5c1.2 30.7 3.5 133.7 7 161.3 3.5 27.6 5.7 72.9 14.3 80.7 8.6 7.7 51.1 12.5 74.3 13.3 23.1.9 106.6 5.9 180.2 5.9s157-5.1 180.2-5.9c23.1-.9 65.7-5.6 74.3-13.3 8.6-7.7 10.7-53 14.3-80.7 3.5-27.6 5.7-130.6 7-161.3 1.2-30.6 0-224.5-3-254.4zM364 729c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8c28.1 0 50.8 22.7 50.8 50.8S392.1 729 364 729zm296 0c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8 50.8 22.7 50.8 50.8S688 729 660 729zm73.3-167.2c-10.5 15.3-84.5 29.8-221.3 29.8s-210.7-14.5-221.3-29.8c-10.5-15.3-5.3-111.3 4.3-166.1s53.6-164 217-164 207.3 109.1 216.9 163.9c9.6 54.8 14.9 150.8 4.4 166.2z" />
					</>
				],
				coach_cc: [
					<>
						<path fill={secondaryColor} d="M285.5 826.2c0 43.3-18.3 104.7 52.7 104.7s59.3-57.3 59.3-100.7m341-4c0 43.3 18.3 104.7-52.7 104.7s-59.3-57.3-59.3-100.7" />
						<path fill={primaryColor} d="M823.4 259.8c-3.4-34.2-3.9-91.8-21.2-111.4-17.3-19.6-233.3-22.8-290.2-22.8s-272.8 3.2-290.2 22.8c-17.3 19.6-17.8 77.2-21.2 111.4s-4.6 255.4-3.1 290.4 4 152.5 8 184 6.5 83.2 16.3 92c9.8 8.8 58.4 14.2 84.8 15.3s121.7 6.8 205.5 6.8 179.1-5.7 205.5-6.8 75-6.4 84.8-15.3c9.8-8.8 12.3-60.5 16.3-92s6.5-149 8-184 .1-256.2-3.3-290.4zM343.1 775.9c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-25.9 58-58 58zm337.8 0c-32 0-58-26-58-58s26-58 58-58 58 26 58 58-26 58-58 58zM764.5 585c-12 17.5-96.5 34-252.5 34s-240.5-16.5-252.5-34-6-127 5-189.5 61-187 247.5-187 236.5 124.5 247.5 187 17 172 5 189.5z" />
					</>,
					<>
						<path d="M313.4 773.2c0 38-16.1 91.7 46.2 91.7s52-50.2 52-88.2m299-3.5c0 38 16.1 91.7-46.2 91.7-62.2 0-52-50.2-52-88.2" />
						<path d="M784.9 276.7c-3-30-3.4-80.5-18.6-97.6s-204.5-20-254.3-20-239.2 2.8-254.3 19.9c-15.2 17.1-15.6 67.7-18.6 97.6s-4 223.9-2.8 254.5c1.2 30.7 3.5 133.7 7 161.3 3.5 27.6 5.7 72.9 14.3 80.7 8.6 7.7 51.1 12.5 74.3 13.3 23.1.9 106.6 5.9 180.2 5.9s157-5.1 180.2-5.9c23.1-.9 65.7-5.6 74.3-13.3 8.6-7.7 10.7-53 14.3-80.7 3.5-27.6 5.7-130.6 7-161.3 1.2-30.6 0-224.5-3-254.4zM364 729c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8c28.1 0 50.8 22.7 50.8 50.8S392.1 729 364 729zm296 0c-28.1 0-50.8-22.7-50.8-50.8s22.7-50.8 50.8-50.8 50.8 22.7 50.8 50.8S688 729 660 729zm73.3-167.2c-10.5 15.3-84.5 29.8-221.3 29.8s-210.7-14.5-221.3-29.8c-10.5-15.3-5.3-111.3 4.3-166.1s53.6-164 217-164 207.3 109.1 216.9 163.9c9.6 54.8 14.9 150.8 4.4 166.2z" />
					</>
				]
			}[type][Number(shape === 'stop')]}
			{checkFavorite && storeFavorites.get(checkFavorite) ? (
				<svg viewBox="0 0 1024 1024" x="768" y="768" width="384" height="384">
					<path fill={viewColors.favorites[0]} stroke={viewColors.favorites[1]} strokeWidth="100" d="M512 927.7l-65.7-59.8C213 656.3 58.9 516.3 58.9 345.5c0-140 109.6-249.2 249.2-249.2 78.8 0 154.5 36.7 203.9 94.2 49.4-57.5 125-94.2 203.9-94.2 139.5 0 249.2 109.2 249.2 249.2 0 170.8-154 310.8-387.4 522.4L512 927.7z" />
				</svg>
			) : null}
		</svg>
	);
}
